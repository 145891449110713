import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "พัฒนาแอปพลิเคชั่นบนมือถือ",
      "style": {
        "position": "relative"
      }
    }}>{`พัฒนาแอปพลิเคชั่นบนมือถือ`}<a parentName="h1" {...{
        "href": "#%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B8%9A%E0%B8%99%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%96%E0%B8%B7%E0%B8%AD",
        "aria-label": "พัฒนาแอปพลิเคชั่นบนมือถือ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
 กว่า 80 เปอเซ็นต์ของลูกค้ายุคปัจจุบันล้วนเข้าใช้งานผ่านมือถือ ดังนั้นการพัฒนาแอปพลิเคชั่นจึงเป็นสิ่งจำเป็นสำหรับทุกองค์กรค์ ทีมพัฒนาของเรามีประสบการณ์พัฒนาแอปพลิเคชั่นมาหลากหลายจึงสามารถตอบโจทย์ของคุณได้แน่นอน
    </LeadParagraph>
    <p>{`เรารับออกแบบให้คำปรึกษาที่เหมาะสม ในการพัฒนาแอปพลิเคชั่นด้วยการใช้เทคโนโลยี่ล่าสุด สามารถรองรับได้ทุกแพลตฟอร์ม ในขณะที่มีความรวดเร็วในการทำงาน ภายใต้ต้นทุนที่ไม่สูงเกินไป`}</p>
    <h2 {...{
      "id": "เทคโนโลยี่ที่เราใช้พัฒนา",
      "style": {
        "position": "relative"
      }
    }}>{`เทคโนโลยี่ที่เราใช้พัฒนา`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%97%E0%B8%84%E0%B9%82%E0%B8%99%E0%B9%82%E0%B8%A5%E0%B8%A2%E0%B8%B5%E0%B9%88%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2",
        "aria-label": "เทคโนโลยี่ที่เราใช้พัฒนา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h5 {...{
      "id": "back-end-api",
      "style": {
        "position": "relative"
      }
    }}>{`Back end API`}<a parentName="h5" {...{
        "href": "#back-end-api",
        "aria-label": "back end api permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <p>{`ASP.NET Core 9, Django(Flask), NodeJs(Express,Nest,Fastify), Laravel, Phoenix framewotk(Elixir), Rust`}</p>
    <h5 {...{
      "id": "mobile-android--ios",
      "style": {
        "position": "relative"
      }
    }}>{`Mobile Android & iOS`}<a parentName="h5" {...{
        "href": "#mobile-android--ios",
        "aria-label": "mobile android  ios permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <p>{`Flutter,Kotlin,Swift,PWA`}</p>
    <Image size="M" src="/images/services/mobile-impl-process.png" alt="Anndream Software" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h2 {...{
      "id": "ตัวอย่างแอปพลิเคชั่นที่เรารับพัฒนา",
      "style": {
        "position": "relative"
      }
    }}>{`ตัวอย่างแอปพลิเคชั่นที่เรารับพัฒนา:`}<a parentName="h2" {...{
        "href": "#%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2",
        "aria-label": "ตัวอย่างแอปพลิเคชั่นที่เรารับพัฒนา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์-wordpress",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ Wordpress`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-wordpress",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ wordpress permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์-shopify",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ Shopify`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-shopify",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ shopify permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี-odooerpnext",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี Odoo,ERPNext`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%AD%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%B5-odooerpnext",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี odooerpnext permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้านตำบล",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้าน/ตำบล`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%AB%E0%B8%B2%E0%B8%A3%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A0%E0%B8%B2%E0%B8%A2%E0%B9%83%E0%B8%99%E0%B8%AB%E0%B8%A1%E0%B8%B9%E0%B9%88%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%95%E0%B8%B3%E0%B8%9A%E0%B8%A5",
        "aria-label": "  แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้านตำบล permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อ-line-oa-ของธุรกิจ",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อ Line OA ของธุรกิจ`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD-line-oa-%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อ line oa ของธุรกิจ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นอื่นๆตามความต้องการ",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นอื่นๆตามความต้องการ`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B8%AD%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B9%86%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3",
        "aria-label": "  แอปพลิเคชั่นอื่นๆตามความต้องการ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h2 {...{
      "id": "ราคา",
      "style": {
        "position": "relative"
      }
    }}>{`ราคา`}<a parentName="h2" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2",
        "aria-label": "ราคา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์-wordpress-ราคาเริ่มต้นที่-75000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ Wordpress ราคาเริ่มต้นที่ 75,000 บาท`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-wordpress-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-75000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ wordpress ราคาเริ่มต้นที่ 75000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์-shopify-ราคาเริ่มต้นที่-85000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ Shopify ราคาเริ่มต้นที่ 85,000 บาท`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-shopify-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-85000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับเว็ปไซต์ shopify ราคาเริ่มต้นที่ 85000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี-odooerpnext-ราคาเริ่มต้นที่-250000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี Odoo,ERPNext ราคาเริ่มต้นที่ 250,000 บาท`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%AD%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%B5-odooerpnext-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-250000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อกับอีอาร์พี odooerpnext ราคาเริ่มต้นที่ 250000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้านตำบล-ราคาเริ่มต้นที่-250000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้าน/ตำบล ราคาเริ่มต้นที่ 250,000 บาท`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%AB%E0%B8%B2%E0%B8%A3%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A0%E0%B8%B2%E0%B8%A2%E0%B9%83%E0%B8%99%E0%B8%AB%E0%B8%A1%E0%B8%B9%E0%B9%88%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%95%E0%B8%B3%E0%B8%9A%E0%B8%A5-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-250000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  แอปพลิเคชั่นเชื่อมบริหารงานภายในหมู่บ้านตำบล ราคาเริ่มต้นที่ 250000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นเชื่อมต่อ-line-oa-ของธุรกิจ-ราคาเริ่มต้นที่-100000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นเชื่อมต่อ Line OA ของธุรกิจ ราคาเริ่มต้นที่ 100,000 บาท**`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD-line-oa-%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-100000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  แอปพลิเคชั่นเชื่อมต่อ line oa ของธุรกิจ ราคาเริ่มต้นที่ 100000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--แอปพลิเคชั่นอื่นๆตามความต้องการ-ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ",
      "style": {
        "position": "relative"
      }
    }}>{`- แอปพลิเคชั่นอื่นๆตามความต้องการ ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B8%AD%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B9%86%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%99%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88",
        "aria-label": "  แอปพลิเคชั่นอื่นๆตามความต้องการ ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Image size="M" src="/images/services/mycustomer.svg" alt="Anndream Software Customers" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h4 {...{
      "id": "ง่ายครบ-จบในที่เดียว-ด้วยประสบการณ์กว่า-10-ปี-คุณมั่นใจ-ได้งานเร็ว-ไม่ทิ้งงาน-มีการดูแลต่อเนื่อง",
      "style": {
        "position": "relative"
      }
    }}>{`ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง`}<a parentName="h4" {...{
        "href": "#%E0%B8%87%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A3%E0%B8%9A-%E0%B8%88%E0%B8%9A%E0%B9%83%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%94%E0%B8%B5%E0%B8%A2%E0%B8%A7-%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%AA%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B2-10-%E0%B8%9B%E0%B8%B5-%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B9%87%E0%B8%A7-%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%97%E0%B8%B4%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99-%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%94%E0%B8%B9%E0%B9%81%E0%B8%A5%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87",
        "aria-label": "ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "สนใจพัฒนาแอปพลิเคชั่น-สามารถปรึกษาได้ฟรี-ติดต่ออีเมล์-anndreamcomgmailcom",
      "style": {
        "position": "relative"
      }
    }}>{`สนใจพัฒนาแอปพลิเคชั่น สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ `}<a parentName="h4" {...{
        "href": "mailto:anndream.com@gmail.com"
      }}>{`anndream.com@gmail.com`}</a><a parentName="h4" {...{
        "href": "#%E0%B8%AA%E0%B8%99%E0%B9%83%E0%B8%88%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99-%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%9B%E0%B8%A3%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%AD%E0%B8%B5%E0%B9%80%E0%B8%A1%E0%B8%A5%E0%B9%8C-anndreamcomgmailcom",
        "aria-label": "สนใจพัฒนาแอปพลิเคชั่น สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ anndreamcomgmailcom permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      